

import simpleCheckboxes from '../components/simple-checkboxes';

export default {
    name: 'monitor',
    components: {
        simpleCheckboxes
    },
    created() {

        document.body.classList.add('pc');

        let t = this;

        for (let i = 1; i <= 18; i++) {
            t.list.people.push({ 'id': (i < 10 ? '0' : '') + i, 'text': i + '人' });
        }

        t.dict.people = listToDict(t.list.people);
        t.dict.source = listToDict(t.list.source);
        t.dict.bell_status = listToDict(t.list.bell_status);
        t.dict.clean_status = listToDict(t.list.clean_status);
        t.dict.check_status = listToDict(t.list.check_status);

        axios.get('employee?role=2,3').then(res => {
            if (res.data.status == 1) {
                t.list.cleaner = res.data.options.items;
                t.dict.cleaner = listToDict(t.list.cleaner);
                t.loadData();
            }
        });

    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {

            let t = this;
            let p = {
                '_withTodayTask': t.today.format('yyyy-MM-dd'),
                '_withTodayOrders': t.today.format('yyyy-MM-dd'),
                '_withNextOrder': t.today.format('yyyy-MM-dd'),
            };

            axios.get('room', { params: p }).then(res => {

                if (res.data.status == 1) {

                    let arr = res.data.options.items;

                    let s = t.stats = {
                        checkin: 0,
                        checkout: 0,
                        checkin_out: 0,
                        continuous: 0,
                        empty: 0,
                    };

                    let todayStr = t.today.format('yyyy-MM-dd');

                    arr.forEach(item => {

                        item.has_checkin = false;
                        item.has_checkout = false;
                        item.has_continuous = false;
                        item.check_status = 0;
                        item.bell_status = 3;
                        item.people = 0;
                        item.is_empty = false;
                        item.is_emergency = false;
                        item._index = 100;
                        item.bgColor = 'bg-pink';

                        if (item.today_orders && item.today_orders.length > 0) {

                            item.today_orders.forEach(order => {
                                if (order.checkin_date == todayStr) {
                                    item.today_order = order;
                                    item.has_checkin = true;
                                    //当日有清扫任务并且已完成
                                    if (!item.today_task || (item.today_task && item.today_task.status == 3)) {
                                        s.checkin++;
                                        item.check_status = 4;
                                    }

                                    if (order.form_id)
                                        item.bell_status = 2;
                                    else
                                        item.bell_status = 1;
                                }
                                else if (order.checkout_date == todayStr) {
                                    item.has_checkout = true;
                                }
                                else {
                                    item.has_continuous = true;
                                    s.continuous++;
                                }
                            });

                            if (item.has_checkin && item.has_checkout) {
                                s.checkin_out++;
                            }
                            else if (!item.has_checkin && item.has_checkout) {
                                s.checkout++;
                            }

                        }
                        else {
                            item.is_empty = true;
                            s.empty++;
                        }

                        if (item.has_checkin && item.today_task && item.today_task.status == 3) {
                            item.bell_status = 3;
                        }

                        if (item.today_task) {
                            item.clean_status = item.today_task.status;
                            item.cleaner_id = item.today_task.cleaner_id;
                        }

                        if (item.next_order) {
                            item.is_emergency = item.next_order.emergency_status > 1;
                            item.people = Number(item.next_order.count_audlt) + Number(item.next_order.count_child) + Number(item.next_order.count_baby);
                            item.source = item.next_order.source;
                        }

                        if (item.check_status == 0) {
                            if (item.has_checkin) {
                                item.check_status = 1;
                            }
                            else if (item.has_checkout) {
                                item.check_status = 2;
                            }
                            else if (item.has_continuous) {
                                item.check_status = 3;
                            }
                        }

                        if (item.today_task) {
                            if (item.today_task.status == 1) {
                                if ((new Date()).getHours() >= 13) {
                                    item.bgColor = 'bg-red';
                                    item._index = 1;
                                }
                                else {
                                    item.bgColor = 'bg-pink';
                                    item._index = 2;
                                }
                            }
                            if (item.today_task.status == 2) {
                                item.bgColor = 'bg-yellow';
                                item._index = 3;
                            }
                            if (item.today_task.status == 3) {
                                item.bgColor = 'bg-green';
                                item._index = 4;
                            }
                        }

                        if (item.is_empty && !item.today_task) {
                            item.bgColor = 'bg-grey';
                            item._index = 110;
                        }

                        if (!item.next_order) {
                            item._index = 120;
                        }

                        item._index += item.check_status;

                    });

                    t.list.room = arr.sort((a, b) => a._index - b._index);

                    t.dict.room = listToDict(t.list.room);

                    t.filterData();
                }
            });

        },

        filterData() {
            let t = this;

            t.list.item.clear();

            let config = {
                bell_status: 'bell_status',
                clean_status: 'clean_status',
                people: 'people',
                check_status: 'check_status',
                source: 'source',
                cleaner: 'cleaner_id',
                room: 'id'
            };

            let values = {
                bell_status: [],
                clean_status: [],
                people: [],
                check_status: [],
                source: [],
                cleaner: [],
                room: []
            };

            for (const key in t.search) {
                t.search[key].forEach(item => {
                    if (key == 'people')
                        values[key].push(Number(item.id));
                    else
                        values[key].push(item.id);
                });
            }

            t.list.room.forEach(item => {

                let pick = true;

                for (const key in values) {
                    let vs = values[key];
                    if (vs.length > 0) {
                        if (vs.indexOf(item[config[key]]) < 0) {
                            pick = false;
                        }
                    }
                }

                if (pick)
                    t.list.item.push(item);

            });

        },

        minusDate() {
            let t = this;

            if (t.today <= Date.today()) {
                A.err('現在および将来のデータへのアクセスのみ');
                return;
            }
            else {
                t.today.setDate(t.today.getDate() - 1);
                t.loadData();
                t.$forceUpdate();
            }
        },

        plusDate() {
            let t = this;
            t.today.setDate(t.today.getDate() + 1);
            t.loadData();
            t.$forceUpdate();
        },

        printA3() {
            if (window._printStyleTag) window._printStyleTag.remove();
            let style = "@media print { @page {  size: A3 landscape !important; }}";

            let s = document.createElement("style");
            s.innerHTML = style;
            document.body.appendChild(s);
            window._printStyleTag = s;
            window.print();
        },

        printA4() {
            if (window._printStyleTag) window._printStyleTag.remove();
            let style = "@media print { @page {  size: A4 landscape !important; }}";

            let s = document.createElement("style");
            s.innerHTML = style;
            document.body.appendChild(s);
            window._printStyleTag = s;
            window.print();
        },

        tapBell(item) {

            let t = this;

            if (item.today_order) {

                if (item.today_order.form_status == 2) {

                    let width = 790;
                    let height = 820;
                    let leftPosition = (window.screen.width - width) / 2;
                    let topPosition = (window.screen.height - height) / 2;
                    let windowFeatures = 'width=' + width + ',height=' + height + ',left=' + leftPosition + ',top=' + topPosition + ',resizable=yes,scrollbars=yes';

                    localStorage['ignorePasswordOnce'] = 1;
                    let popup = window.open(location.origin + '/#/a4form?date=' + item.today_order.checkin_date + '&rid=' + item.today_order.room_id, '_blank', windowFeatures);

                    return;
                }

                if (item.today_order.form_status != 1)
                    return;

                t.editingItem = item;
                t.showUnfillForm = true;

            }

        },

        resolveUnfill() {
            let t = this;

            let width = 420;
            let height = 800;
            let leftPosition = (window.screen.width - width) / 2;
            let topPosition = (window.screen.height - height) / 2;
            let windowFeatures = 'width=' + width + ',height=' + height + ',left=' + leftPosition + ',top=' + topPosition + ',resizable=yes,scrollbars=yes';

            let popup = window.open(location.origin + '/#/form?rid=' + t.editingItem.today_order.room_id, '_blank', windowFeatures);

            function handleMsg(event) {
                if (event.source === popup && typeof event.data === 'string') {
                    window.removeEventListener('message', handleMsg);
                    t.editingItem.today_order.form_status = 2;
                    t.editingItem.bell_status = 2;
                    t.$forceUpdate();
                }
            }

            window.addEventListener('message', handleMsg);
        },

        goContact() {
            this.showUnfillForm = false;
        },


    },
    data() {
        return {
            showUnfillForm: false,
            editingItem: null,
            today: Date.today(),
            todayStr: Date.today().format('yyyy-MM-dd'),
            search: {
                bell_status: [],
                clean_status: [],
                people: [],
                check_status: [],
                source: [],
                cleaner: [],
                room: []
            },
            list: {
                bell_status: [
                    { id: 1, text: 'Form 未提出', className: 'fill-red' },
                    { id: 2, text: 'Form 提出完了、異常なし', className: 'fill-white' },
                    { id: 3, text: '当日掃除があり、客なし', className: 'fill-black' },
                    { id: 4, text: '騒音大きい', className: 'fill-yellow' },
                    { id: 5, text: '注文あり', className: 'fill-purple' },
                ],
                clean_status: [
                    { id: 1, text: '清掃未開始' },
                    { id: 2, text: '清掃中' },
                    { id: 3, text: '清掃完了' },
                ],
                check_status: [
                    { id: 0, text: 'がらがら', suffix: '<b style="color: #000">空</b>' },
                    { id: 1, text: 'チェックイン/アウト', suffix: '<b style="color: #556FF1">IN</b>' },
                    { id: 2, text: 'チェックアウトだけ', suffix: '<b style="color: #FF4D4D">OUT</b>' },
                    { id: 3, text: '滞在中', suffix: '<b style="color: #A7A7A7">ST</b>' },
                    { id: 4, text: 'Check IN 可', suffix: '<b style="color: #8e2de2">可</b>' },
                ],
                source: [
                    { id: 'BB', text: 'BB：airbnb' },
                    { id: 'AG', text: 'AG：agoda' },
                    { id: 'VS', text: 'VS：Vacation STAY' },
                    { id: 'RT', text: 'RT：Rakuten Travel' },
                    { id: 'CT', text: 'CT：Trip(携程海外)' },
                    { id: 'XP', text: 'XP：Xpedia' },
                    { id: 'KL', text: 'KL：自社予約' },
                    { id: 'SF', text: 'SF：会社招待等' },
                ],
                room: [],
                cleaner: [],
                people: [],
                item: []
            },
            dict: {
                cleaner: {},
                room: {},
                clean_status: {},
                bell_status: {},
                people: {},
                source: {},
            },
            showFormPopover: false,
            showCleanStatusPopover: false,
            showPeoplePopover: false,
            showCheckStatusPopover: false,
            showSourcePopover: false,
            showRoomPopover: false,
            showCleanPopover: false,
            stats: {
                checkin: 0,
                checkout: 0,
                checkin_out: 0,
                continuous: 0,
                empty: 0,
            }
        };
    },
    computed: {
    }
};
