import Vue from 'vue'

import Vant from 'vant';
import 'vant/lib/index.css';

import Index from './Index'
import router from './router'
import t from './components/t'
import passwordBox from './components/password-box'
import statsHeader from './components/stats-header'
import userBox from './components/user-box'
import sideMenu from './components/side-menu'

import 'animate.css';
import '@vant/touch-emulator';

import {
  Locale
} from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';

const messages = {
  'en-US': {
    vanCalendar: {
      monthTitle: (year, month) => {
        let arr = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        return year + ' ' + arr[month - 1];
      }
    },
  },
};

Locale.use('en-US', enUS);
Locale.add(messages);

Vue.use(Vant);
Vue.component('t', t);
Vue.component('userBox', userBox);
Vue.component('sideMenu', sideMenu);
Vue.component('passwordBox', passwordBox);
Vue.component('statsHeader', statsHeader);

Vue.config.productionTip = false

window.B = {};

window.S = {
  'set': function (key, value) {

    if (typeof (value) == 'object')
      value = JSON.stringify(value);

    localStorage.setItem(key, value);

  },
  'get': function (key) {
    var value = localStorage.getItem(key);

    try {
      return JSON.parse(value);
    } catch (ex) {
      return value;
    }
  },
  'remove': function (key) {
    if (typeof key == 'object' && key.hasOwnProperty('length'))
      for (var i = 0; i < key.length; i++) {
        localStorage.removeItem(key[i]);
      }
    else
      localStorage.removeItem(key);
  },
  'clear': function () {
    var data = getFields(localStorage, ['lang'], false);
    localStorage.clear();
    for (var name in data) {
      localStorage.setItem(name, data[name]);
    }
  }
};

window.A = {
  'show': function (body, title, type) {
    return Swal.mixin({
      customClass: {
        confirmButton: 'btn-lg btn-orange',
        cancelButton: 'btn-sm btn-dark mr10'
      },
      confirmButtonText: Vue.prototype.t('確認'),
      showCancelButton: false,
      buttonsStyling: false,
    }).fire(title, body, type || '');
  },
  'err': function (body, title) {
    return Swal.mixin({
      customClass: {
        confirmButton: 'btn-lg btn-orange',
        cancelButton: 'btn-sm btn-dark mr10'
      },
      confirmButtonText: Vue.prototype.t('確認'),
      showCancelButton: false,
      buttonsStyling: false,
    }).fire(title, body, 'error');
  },
  'toast': function (text, duration, type) {
    return Swal.mixin({
      timer: duration || 1000,
      timerProgressBar: true,
      buttonsStyling: false,
      showCancelButton: false,
      showConfirmButton: false
    }).fire(text, '', type || 'success');
  },
  'safety': function (content, type, onlyAlert) {

    return Swal.mixin({
      customClass: {
        confirmButton: 'btn-lg btn-orange',
        cancelButton: 'btn-sm btn-dark mr10'
      },
      confirmButtonText: Vue.prototype.t('確認'),
      cancelButtonText: Vue.prototype.t('取消'),
      buttonsStyling: false,
      showCancelButton: !onlyAlert
    }).fire(Vue.prototype.t('内容確認'), content, type || 'warning');

  }
};

Date.prototype.format = function (fmt) {
  var o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    'S': this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
  return fmt;
};

Date.today = function () { return new Date(new Date().format('yyyy/MM/dd')) };

Array.prototype.clear = function () {
  this.length = 0;
};

Array.prototype.remove = function (item) {
  var index = this.indexOf(item);
  if (index > -1)
    this.splice(index, 1);
  return this;
};

Array.prototype.removeAll = function (items) {

  for (var i = 0; i < items.length; i++) {
    var item = items[i];
    var index = this.indexOf(item);
    if (index > -1)
      this.splice(index, 1);
  }
  return this;
};

Array.prototype.append = function (items) {
  for (var i = 0; i < items.length; i++) {
    var item = items[i];
    var index = this.indexOf(item);
    if (index < 0)
      this.push(item);
  }
  return this;
};

window.getFields = function (model, fields, isInclude) {
  isInclude = isInclude == undefined ? true : isInclude;

  var result = {};

  if (isInclude) {
    for (var i = 0; i < fields.length; i++) {

      var field = fields[i];

      if (typeof field == "string") {
        if (model[field] != null)
          result[field] = model[field];
      } else if (typeof field == "object") {
        for (var source in field) {
          var fieldName = field[source];
          result[fieldName] = model[source];
          break;
        }
      }
    }
  } else {
    for (var name in model) {

      var value = model[name];

      if (fields.indexOf(name) < 0 && value != null)
        result[name] = value;

    }
  }

  return result;
}

window.listToDict = function (list, keyName) {

  keyName = keyName || 'id';

  var dict = {};

  if (list && list.length > 0) {
    for (var i = 0; i < list.length; i++) {
      var item = list[i];
      dict[item[keyName]] = item;
    }
  }

  return dict;

}

window.listToDict2 = function (list, keyName, valueName) {

  keyName = keyName || 'id';

  var dict = {};

  if (list && list.length > 0) {
    for (var i = 0; i < list.length; i++) {
      var item = list[i];
      dict[item[keyName]] = item[valueName];
    }
  }

  return dict;

}

window.mirror = function (source, target) {

  target = target || {};

  for (var name in source) {
    target[name] = source[name];
  }

  return target;
}


window.mirrorSet = function (source, target) {

  target = target || {};

  for (var name in source) {
    Vue.set(target, name, source[name]);
  }

  return target;
}

window.mirror2 = function (source, target) {

  target = target || {};

  for (var name in target) {
    target[name] = source[name];
  }

  return target;
}

window.combineObject = function (o1, o2) {
  var r = {};

  for (var key in o1) {
    r[key] = o1[key];
  }

  for (var key in o2) {
    r[key] = o2[key];
  }

  return r;
}

window.getURLParams = function () {

  var r;
  if (location.search && location.search.indexOf('?') > -1) {

    r = location.search.split('?')[1].split('&');

  }

  if (location.hash && location.hash.indexOf('?') > -1) {

    r = location.hash.split('?')[1].split('&');

  }

  var obj = {};

  if (r && r.length > 0) {

    r.forEach(function (item) {
      var _tmp = item.split('=');
      obj[_tmp[0]] = _tmp[1];
    });

    return obj;
  }



  return {};

}

window.compressImg = function (file, options, callback) {
  var imgname = file.name;
  var imgtype = (imgname.substring(imgname.lastIndexOf('.') + 1)).toLowerCase();
  if (imgtype == 'jpg' || imgtype == 'jpeg') {
    imgtype = 'image/jpeg';
  } else {
    imgtype = 'image/png';
  }
  // 用FileReader读取文件
  var reader = new FileReader();
  // 将图片读取为base64
  reader.readAsDataURL(file);
  reader.onload = function (evt) {
    var base64 = evt.target.result;
    window._compressImg(base64, options, callback, imgtype);
  }
};
window._compressImg = function (base64, options, callback, imgtype) {
  imgtype = imgtype || 'image/png';
  // 创建图片对象
  var img = new Image();
  // 用图片对象加载读入的base64
  img.src = base64;



  img.onload = function () {
    var that = this,
      canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d');

    var maxWidth = options.max_width;
    var maxHeight = options.max_height;

    var targetWidth, targetHeight;

    if (img.width > maxWidth && img.height > maxHeight) {
      const rate = Math.min(maxWidth / img.width, maxHeight / img.height)
      targetWidth = img.width * rate
      targetHeight = img.height * rate
    } else if (img.width > maxWidth) {
      targetWidth = maxWidth
      targetHeight = (maxWidth / img.width) * img.height
    } else if (img.height > maxHeight) {
      targetHeight = maxHeight
      targetWidth = (maxHeight / img.height) * img.width
    } else {
      targetWidth = img.width
      targetHeight = img.height
    }

    canvas.setAttribute('width', targetWidth);
    canvas.setAttribute('height', targetHeight);
    // 将图片画入canvas
    ctx.drawImage(that, 0, 0, targetWidth, targetHeight);

    // 压缩到指定体积以下（M）
    if (options.size) {
      var scale = 0.9;
      (function f(scale) {
        if (base64.length / 1024 / 1024 > options.size && scale > 0) {
          base64 = canvas.toDataURL(imgtype, scale);
          scale = scale - 0.1;
          f(scale);
        } else {
          callback(base64);

        }
      })(scale);
    } else if (options.scale) {
      // 按比率压缩
      base64 = canvas.toDataURL(imgtype, options.scale);
      callback(base64);
    } else if (options.max_height || options.max_height) {
      base64 = canvas.toDataURL(imgtype, 1);
      callback(base64);
    }

  }
}

window.getObjectURL = function (file) {
  var url = null;
  if (window.createObjectURL != undefined) { //basic
    url = window.createObjectURL(file);
  } else if (window.URL != undefined) { //mozilla(firefox)兼容火狐
    url = window.URL.createObjectURL(file);
  } else if (window.webkitURL != undefined) { //webkit or chrome
    url = window.webkitURL.createObjectURL(file);
  }
  return url;
}

window.filter = function (arr, fn) {
  var res = [];
  for (var i = 0; i < arr.length; i++) {
    if (fn(arr[i]))
      res.push(arr[i]);
  }
  return res;
}

window.pluck = function (items, key) {
  var arr = [];
  for (var i = 0; i < items.length; i++) {
    arr.push(items[i][key]);
  }
  return arr;
}

window.pick = function (items, fields) {
  var arr = [];
  for (var i = 0; i < items.length; i++) {
    let item = {};
    for (var j = 0; j < fields.length; j++) {
      item[fields[j]] = items[i][fields[j]];
    }
    arr.push(item);
  }
  return arr;
}

window.copyFrom = function (source, target) {
  for (const key in source) {
    source[key] = target[key];
  }
}

window.addMonthsToDate = function (date, months) {
  // 获取日期的年份和月份
  var year = date.getFullYear();
  var month = date.getMonth();

  // 计算新的月份和年份
  var newMonth = month + months;
  var newYear = year;

  // 如果新月份小于0，需要调整年份和月份
  if (newMonth < 0) {
    // 计算需要减去多少年
    var yearsToSubtract = Math.floor(Math.abs(newMonth) / 12);
    newYear -= yearsToSubtract;
    // 更新月份，注意月份是从0开始的，所以这里使用负数
    newMonth += yearsToSubtract * 12;
  }

  // 创建一个新的日期对象
  var newDate = new Date(newYear, newMonth, 1);

  // 如果原始日期的天数大于新月份的天数，则将日期设置为新月份的最后一天
  if (date.getDate() > new Date(newYear, newMonth + 1, 0).getDate()) {
    newDate = new Date(newYear, newMonth, new Date(newYear, newMonth + 1, 0).getDate());
  }

  return newDate;
}

window.getDaysInMonth = function (year, month) {
  // 注意：JavaScript中的月份是从0开始的，即0代表1月，11代表12月
  month = month - 1; // 调整月份以适应JavaScript的月份计数方式

  // 创建一个日期对象，设置为指定年份和月份的第一天
  var date = new Date(year, month, 1);

  // 获取下个月的第一天
  var nextMonth = new Date(year, month + 1, 1);

  // 计算两个日期之间的天数差，即为该月的天数
  var days = (nextMonth - date) / (1000 * 60 * 60 * 24);

  return days;
}


window.ImageUploader = {
  'openSelector': function (multiple) {
    var fImage = document.getElementById('fImage');
    fImage.removeAttribute('capture');
    if (multiple)
      fImage.multiple = true;
    else
      fImage.multiple = false;

    fImage.click();
  },
  'openCamera': function () {
    var fImage = document.getElementById('fImage');
    fImage.setAttribute('capture', 'camera');
    fImage.multiple = false;
    fImage.click();
  },
  'onSelectFile': function () {

    var inputFile = window.event.target;

    var file = inputFile.files[0];

    if (!file)
      return;

    if (file.type.match('image.*')) {
      if (ImageUploader.onSelectImage)
        ImageUploader.onSelectImage(file);

      if (ImageUploader.onSelectImages)
        ImageUploader.onSelectImages(inputFile.files);
    } else {

    }
    inputFile.value = '';
  },
  'onSelectImage': function () {

  },
  'onSelectImages': function () {

  }
};


Vue.prototype.url = function (url) {
  if (!url)
    return url;
  if (url.indexOf('assets') > -1 || url.indexOf('blob:') > -1 || url.indexOf('http') == 0)
    return url;
  else if (/localhost|dev/.test(location.host))
    return '//localhost/kyoran/api/public/res/' + url;
  else
    return '//hk.kyoran.jp/res/' + url;
}

Vue.prototype.url1 = function (urls) {
  return Vue.prototype.url(urls.split(',')[0]);
}

Vue.prototype.thumb = function (url) {
  if (/localhost|dev/.test(location.host))
    return '//localhost/kyoran/api/public/res/' + url.replace('img/', 'thumb/');
  else
    return '//hk.kyoran.jp/res/' + url.replace('img/', 'thumb/');
}

Vue.prototype.thumb1 = function (urls) {
  return Vue.prototype.thumb(urls.split(',')[0]);
}

Vue.prototype.makeSort = function (sort) {
  let order = [];
  for (let key in sort) {
    if (sort[key] > 0)
      order.push(key + '-' + sort[key]);
  }
  return order.join(',');
}

Vue.prototype.sortToggle = function (sort, key) {

  let old = sort[key];

  let value;

  for (let _key in sort) {
    sort[_key] = 0;
  }

  if (old == 0)
    value = 2;
  else if (old == 1)
    value = 2;
  else if (old == 2)
    value = 1;

  sort[key] = value;

}

Vue.prototype.stopPropagation = function () {
  event.stopPropagation();
}

Vue.filter('number', function (num) {
  var suffix = '';

  num = Number(num);
  if (num.toString().indexOf('.') > -1) {
    suffix = '.' + num.toString().split('.')[1];
    num = num.toString().split('.')[0];
  }
  var result = [],
    counter = 0;
  num = (num || 0).toString().split('');
  for (var i = num.length - 1; i >= 0; i--) {
    counter++;
    result.unshift(num[i]);
    if (!(counter % 3) && i != 0) {
      result.unshift(',');
    }
  }
  return result.join('') + suffix;
});

Vue.filter('friendly', function (date) {
  var d = new Date(date);
  return d.format('MM/dd hh:mm');
});

Vue.filter('friendly2', function (date) {
  return date.replace(':', '时') + '分';
});

Vue.filter('friendly3', function (date) {
  return new Date(date).format('MM月dd日');
});

Vue.filter('plainText', function (html) {
  if (!html) return '';
  var d = document.createElement("div");
  d.innerHTML = html;
  return d.innerText;
});

Vue.filter('n', function (number) {
  return Number(number);
});

Vue.filter('d', function (date) {
  if (!date)
    return '';
  return new Date(date).format('M/d hh:mm')
});

Vue.filter('d2', function (date) {
  if (!date)
    return '';
  return new Date(date).format('M/d')
});

Vue.filter('d3', function (date) {
  if (!date)
    return '';
  return new Date(date).format('h:s')
});

Vue.filter('d4', function (date) {
  if (!date)
    return '';
  return new Date(date).format('yyyy.MM.dd')
});

Vue.filter('d5', function (date) {
  if (!date)
    return '';
  return new Date(date).format('yyyy/MM/dd')
});
Vue.filter('d6', function (date) {
  if (!date)
    return '';
  return new Date(date).format('yyyy/MM')
});

Vue.filter('d7', function (date) {
  if (!date)
    return '';
  return new Date(date).format('MM/dd')
});

Vue.filter('d8', function (date) {
  if (!date)
    return '';
  return new Date(date).format('yyyy年MM月dd日')
});

Vue.filter('sn', function (id) {
  id = id.toString();
  if (id.length == 1)
    id = "00" + id;
  else if (id.length == 2)
    id = "0" + id;
  return 'KH-' + id;
});

Vue.filter('days', function (date) {
  return Math.ceil(((new Date()) - (new Date('2022-08-15 19:31:39'))) / (24 * 60 * 1000 * 60));
});

Vue.filter('firstline', function (text) {
  if (text) {
    return text.split("\n")[0];
  }
  return "";
});

Vue.filter('pad2', function (v) {
  if(Number(v) < 1)
    return v;
  v = v.toString();
  if (v.length == 1)
    return "0" + v;
  else
    return v;
});

Vue.prototype.hl = function (text, keywords) {
  if (text) {
    return text.replace(keywords, '<b class="tr">' + keywords + '</b>')
  }
  return "";
};

Vue.filter('hl', Vue.prototype.hl);

Vue.prototype.br = function (text) {
  if (text) {
    return text.replace(/\n/g, "<br>");
  }
  return "";
}

Vue.filter('br', Vue.prototype.br);

Vue.prototype.t = function (v) {
  if (window._i18n[window._language][v])
    return window._i18n[window._language][v];
  else
    return v;
}

Vue.prototype.minutesText = function (minutes) {

  // 计算小时和剩余分钟
  const hours = Math.floor(minutes / 60); // 获取完整的小时数
  const remainingMinutes = minutes % 60; // 获取剩余的分钟数

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(remainingMinutes).padStart(2, '0');

  return formattedHours + 'h' + formattedMinutes + 'm';
}


Vue.prototype.getMondayOfThisWeek = function (date) {
  date = date ? date : Date.today();
  let dayOfWeek = date.getDay();
  let diff = date.getDate() - dayOfWeek + (dayOfWeek == 0 ? -6 : 1); // 调整当今天是周日的情况
  return new Date(date.setDate(diff));
}

Vue.prototype.calcDateDiffDays = function (dateString1, dateString2) {
  // 将日期字符串转换为 Date 对象
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  // 将时间戳转换为毫秒，并计算天数差
  const timeDifference = date2 - date1;
  const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

  return dayDifference;
}

Vue.prototype.initSocket = function () {

  let t = this;

  let sok = new WebSocket(t.wsURL);

  const HEARTBEAT_INTERVAL = 10000;

  let heartbeatIntervalId;

  let sendHeartbeat = window.sendHeartbeat = function () {
    sok.send(JSON.stringify({ heartbeat: true }));
  }

  sok.addEventListener('open', function (event) {
    console.log('socket is open');
    sendHeartbeat();
    heartbeatIntervalId = setInterval(sendHeartbeat, HEARTBEAT_INTERVAL);
  });

  sok.addEventListener('message', function (event) {
    t.receiveMessage(event);
  });

  sok.onclose = function () {
    clearInterval(heartbeatIntervalId);
  };

  window.sok = sok;
}

Vue.prototype.sendMessage = function (extras) {

  let t = this;

  let sok = window.sok;

  if (sok && sok.readyState === WebSocket.OPEN) {
    sok.send(JSON.stringify({
      event: 'room_sync',
      extras: extras
    }));
  }
  else {

    clearInterval(window.connectSocketTimer);
    t.initSocket();
    sok = window.sok;
    window.connectSocketTimer = setInterval(() => {
      if (sok && sok.readyState === WebSocket.OPEN) {
        sok.send(JSON.stringify({
          event: 'room_sync',
          extras: extras
        }));
        clearInterval(window.connectSocketTimer);
      }
    }, 50);

  }
}

new Vue({
  render: h => h(Index),
  eventHub: new Vue(),
  router
}).$mount('#app');

if (/iPhone/.test(window.navigator.userAgent)) {
  document.body.classList.add('platform-ios');
}

window.buildURLParams = function (obj) {

  var items = [];

  for (var name in obj) {
    items.push(name + '=' + encodeURIComponent(obj[name]));
  }
  return items.join('&');
}