

export default {
    name: "ClockInSupReview",
    props: {
    },
    created() {

        let t = this;

        t.search.type = t.list.type[0];

        t.dict.status = listToDict(t.list.status);

        axios.get('employee?role=4').then(res => {
            if (res.data.status == 1) {
                let arr = res.data.options.items;
                t.list.employee = [];
                arr.forEach(e => {
                    e.fullname = e.lastname + e.firstname;
                    if (e.id != t.U.id)
                        t.list.employee.push(e);
                });
                t.list.employee.unshift({
                    id: -1,
                    fullname: '全部'
                });
            }
        });

        t.loadData();
    },
    activated() {


    },
    methods: {
        loadData() {
            let t = this;

            let s = t.search;

            let params = {};

            if (s.status && s.status.id > -1)
                params.status = s.status.id;

            if (s.employee && s.employee.id > -1)
                params.employee_id = s.employee.id;

            if (t.search.type.id == 1) {

                axios.get('clockInSup?_with=employee', { params: params }).then(res => {
                    if (res.data.status == 1) {
                        t.list.item = res.data.options.items;
                    }
                });

            } else if (t.search.type.id == 2) {
                axios.get('clockInFieldWork?_with=employee', { params: params }).then(res => {
                    if (res.data.status == 1) {
                        t.list.item = res.data.options.items;
                    }
                });

            }
        },

        confirm(item) {

            let t = this;

            if (t.search.type.id == 1)
                A.safety(t.t('打刻申請を通すことを確認しますか？')).then(function (res) {
                    if (res) {
                        axios.post('clockIn/confirmSup/' + item.id).then(res => {
                            if (res.data.status == 1) {
                                item.status = 1;
                            }
                        });
                    }
                });
            else if (t.search.type.id == 2)
                A.safety(t.t('Are you sure you want to approve this application?')).then(function (res) {
                    if (res) {
                        axios.post('clockIn/confirmFieldWork/' + item.id).then(res => {
                            if (res.data.status == 1) {
                                item.status = 1;
                            }
                        });
                    }
                });
        },

        reject(item) {

            let t = this;

            if (t.search.type.id == 1)
                A.safety(t.t('打刻申請を却下することを確認しますか？')).then(function (res) {
                    if (res) {
                        axios.post('clockIn/rejectSup/' + item.id).then(res => {
                            if (res.data.status == 1) {
                                item.status = 2;
                            }
                        });
                    }
                });
            else if (t.search.type.id == 2)
                A.safety(t.t('Are you sure you want to reject this application?')).then(function (res) {
                    if (res) {
                        axios.post('clockIn/rejectFieldWork/' + item.id).then(res => {
                            if (res.data.status == 1) {
                                item.status = 2;
                            }
                        });
                    }
                });
        },

        selectStatus(v) {
            this.search.status = v;
            this.showStatusSelector = false;
            this.loadData();
        },
        selectEmployee(v) {
            this.search.employee = v;
            this.showEmployeeSelector = false;
            this.loadData();
        },
        selectType(v) {
            this.search.type = v;
            this.showTypeSelector = false;
            this.loadData();
        },
    },
    data() {
        return {
            busying: false,
            showStatusSelector: false,
            showEmployeeSelector: false,
            showTypeSelector: false,
            dict: {
                status: {}
            },
            list: {
                item: [],

                type: [
                    {
                        'id': 1,
                        'name': '時間変更'
                    },
                    {
                        'id': 2,
                        'name': '直行直帰'
                    },
                    {
                        'id': 3,
                        'name': '休み変更'
                    }
                ],
                status: [
                    {
                        'id': -1,
                        'name': '全部'
                    },
                    {
                        'id': 0,
                        'name': '承認待ち'
                    },
                    {
                        'id': 1,
                        'name': '承認済'
                    },
                    {
                        'id': 2,
                        'name': '却下'
                    }
                ]
            },
            search: {
                employee: null,
                status: null,
                type: null,
            },

        };
    },
};

